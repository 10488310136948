import React, { useEffect, useState } from "react";
import { Menu, Dropdown } from "antd";
import { CheckOutlined , DownOutlined} from "@ant-design/icons";

const CountryCodeSelector = () => {
  const options = [
    { value: "GH", label: "Ghana" },
    { value: "CI", label: "Ivory Coast" },
  ];

  const [selectedCountry, setSelectedCountry] = useState("");
  const defaultCountryCode =localStorage.getItem('defaultCountryCode')

  const handleSelect = (value, label) => {
    setSelectedCountry(label);
    localStorage.setItem("selectedCountryCode", value);
    window.location.reload();
  };

  useEffect(() => {
    let selectedCountryCode = localStorage.getItem("selectedCountryCode");
    if (!selectedCountryCode) {
      selectedCountryCode = defaultCountryCode;
      localStorage.setItem("selectedCountryCode", selectedCountryCode);
    }
    const selectedOption = options.find(
      (option) => option?.value === selectedCountryCode
    );
    setSelectedCountry(selectedOption?.label);
  }, [selectedCountry]);

  const menu = (
    <Menu onClick={(e) => handleSelect(e.key, e.item.props.children)}>
      {options.map((option) => (
        <Menu.Item key={option?.value}>
          <span className="d-flex justify-content-between align-items-center">
            {option?.label}
            {selectedCountry === option?.label ? (
              <CheckOutlined className="text-success" />
            ) : null}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <div className="d-flex align-items-center">
        <span className="font-weight-semibold text-gray">{selectedCountry || "Select Country Code"} <DownOutlined className="font-size-xs"/></span>
        </div>
      </a>
    </Dropdown>
  );
};

export default CountryCodeSelector;
